#about {
	/* height: 100vh;
    width: 100vw; */
	/* display: flex; */
	/* flex-direction: row;
    flex-wrap: wrap; */
	border-bottom: 10px solid #666066;
	background-image: linear-gradient(
		to right top,
		#d16ba5,
		#c777b9,
		#ba83ca,
		#aa8fd8,
		#9a9ae1,
		#8aa7ec,
		#79b3f4,
		#69bff8,
		#52cffe,
		#41dfff,
		#a1e7ec,
		#5ffbf1
	);
}
.App {
	background-color: #8aa7ec;
	color: white;
	text-align: center;
	overflow-x: hidden;
	overflow-y: hidden;
}

#personalImage {
	border-radius: 60%;
	object-fit: contain;
	/* width: 300px; */
	height: 300px;
	/* margin-top: 5%; */
	background-color: #231e23;
	border: 5px solid #5eaec7;
}


#githubpic {
	width: 5%;
	height: 5%;
	border-radius: 40%;
}

#linkedinpic {
	width: 5%;
	height: 5%;
}

.picturegallery {
	border-radius: 45px;
	width: 50%;
	object-fit: contain;
	height: 100%;
	border: 3px solid #fff;
}

.projects-title {
	background: linear-gradient(180deg, transparent 60%, #8fe5ff 0);
	font-size: 2rem;
	width: fit-content;
	margin: auto;
	color: #bf1363;
}

#project-container {
	border-radius: 20px;
	box-shadow: 5px 7px 5px rgb(110 106 106 / 58%);
	display: flex;
	flex-direction: column;
	border: 1px solid rgba(139, 135, 135, 0.247);
	margin: 20px auto;
	width: 60vw;
	padding: 30px;
	background-color: #8aa7ec;
}

.project-name {
	color: #bf1363;
	font-family: fantasy;
}

.project-description {
	color: white;
	font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

#projects {
	background-color: white;
}

#contact-container {
	margin: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 95%;
	border-radius: 20px;
	box-shadow: 10px 10px 10px rgb(54 53 53 / 32%);
	color: #bf1363;
	background-color: rgba(208, 221, 236, 0.6509803921568628);
}

#about-container {
	margin: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	padding-top: 20px;
}

#githubpic {
	width: 80px;
	height: 80px;
	border-radius: 40%;
}

#linkedinpic {
	width: 80px;
	height: 80px;
}

.get-to-know-me {
	font-size: medium;
	margin: 3%;
	width: auto;
	font-family: "Zen Kaku Gothic Antique", sans-serif;
}

.name-header {
	background: linear-gradient(180deg, transparent 80%, #e8ff8d 0);
	background-size: 50%;
	color: #bf1363;
	font-family: "Zen Kaku Gothic Antique", sans-serif !important;
	font-size: 2rem;
	font-weight: 500;
	width: fit-content;
	margin: auto;
}
